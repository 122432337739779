<template>
  <div>
    <Row :gutter="24">
      <Col :span="6">
        <Card>
          <p slot="title">客户档案信息</p>
          <!-- <Tag  color="blue">{{dataobj.ProcessStatusDesc}}</Tag> -->
          <Button
            slot="extra"
            style="margin-left: 30px"
            type="primary"
            @click="Extract()"
            icon="md-barcode"
            >抽查</Button
          >
          <Button
            v-if="agentId==1||agentId==5"
            slot="extra"
            style="margin-left: 10px;"
            type="info"
            @click="ViewVR()"            
          >全景图</Button>
          <Button
            slot="extra"
            style="margin-left: 10px"
            type="info"
            @click="GoBack()"
            icon="md-arrow-back"
            >返回</Button
          >
          <!-- <p>异常问题: {{ dataobj.ProblemsName }}</p> -->
          <!-- <div v-if="dataobj.flag == true"><p>电子签名: </p><Button
            
            style="margin-left: 30px;"
            type="ifno"
            @click="ViewSignImgs()"
          >查看</Button></div> -->
          <div v-if="dataobj.flag == true"><p>电子签名: </p>
            <!-- <div class="img-list">
              <div
                v-for="(item, index) in siimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div> -->
            <div class="img-list"
                v-bind:style="{'background-image':'url(' + siimgurl+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','width': '100%','height': '75px'}">
            </div>
          </div>
          <p>质保卡号: {{ dataobj.WarrantyNum }}</p>
          <p>客户姓名: {{ dataobj.CustName }}</p>
          <p>客户电话: {{ dataobj.CustTel }}</p>
          <p>详细地址: {{ dataobj.CustAddress }}</p>
          <p>厨房: {{ dataobj.KitchenNum }} 个</p>
          <p>卫生间: {{ dataobj.WashNum }} 个</p>
          <!-- <p>阀门品牌: {{ dataobj.Brand }}</p> -->
          <p>户型: {{ dataobj.HouseTypeName }}</p>
          <p>户型满足条件: {{ dataobj.ConditionName }}</p>
          <p>详细类型: {{ dataobj.DetailType }}</p>
          <p>管道米数: {{ dataobj.PipeLength }}</p>
          <p>管道类型: {{ dataobj.PipeLineName }}</p>
          <p>是否走顶回水: {{ dataobj.InstallTypeDesc }}</p>
          <p>是否二次试压: {{ dataobj.IsSecondPre == "1" ? "是" : "否" }}</p>
          <p>是否远程: {{ dataobj.IsDistant == "1" ? "是" : "否" }}</p>
          <p>是否上传业主群推广图片:{{ dataobj.IsPopularize == "1" ? "是" : "否" }}</p>
          <p>是否上传朋友圈推广图片: {{ dataobj.IsWechat == "1" ? "是" : "否" }}</p>
          <p>朋友圈数量: {{ dataobj.WechatNum }}</p>
          <p>是否上传VR全景图片: {{ dataobj.IsUploadVR == "1" ? "是" : "否" }}</p>
          <p>是否家装单: {{dataobj.IsCompany=="1"?"是":"否"}}</p>
          <p>试压员: {{ dataobj.WorkerName }}</p>
          <p>试压类型: {{dataobj.PressureTypeName}}</p>
          <!-- <p>配套产品积分: {{dataobj.OtherProductIntegral}}</p> -->
          <p>预约单备注: {{ dataobj.PreRemark }}</p>
          <p>是否使用全套大流量系统: {{dataobj.IsBigFlow=="1"?"是":"否"}}</p>
          <p>客户档案备注: {{ dataobj.Remark }}</p>
          <p>家装公司姓名: {{dataobj.CompanyName}}</p>
          <!-- <p>预约经销商: {{dataobj.DealerName}}</p> -->
          <div v-if="dataobj.Company>0"><p>预约经销商: {{dataobj.PreDealerName}}({{dataobj.DealerCorp}})</p></div>
          <div v-else><p>预约经销商: {{dataobj.PreDealerName}}</p></div>
          <p>归属经销商: {{dataobj.DealerName}}</p>
          <p>归属经销商原因: {{dataobj.Remark1}}</p>
          <p>安装不规范备注: {{dataobj.StandardDesc}}</p>
          <p>试压开始时间: {{ dataobj.StartGuaranteedTime }}</p>
          <p>加压前压力: {{ dataobj.PrePressure }} 公斤</p>
          <p>试压结束时间: {{ dataobj.EndGuaranteedTime }}</p>
          <p>加压后压力: {{ dataobj.AftPressure }} 公斤</p>
          <p>不锈钢水管备注: {{dataobj.PipeRemark}}</p>
          <!-- <p>中奖奖品: {{dataobj.PrizeName}}</p> -->
          <!-- <p>
            是否赠送促销活动产品:
            {{ dataobj.IsGiveProduct == "1" ? "是" : "否" }}
          </p> -->
          <p>不合格原因: {{ dataobj.UnReason }}</p>
          <!-- <div v-if="dataobj.WarrantyNum.indexOf('W') == 0||dataobj.WarrantyNum.indexOf('R') == 0"><p>电子签名: </p><Button
            
            style="margin-left: 30px;"
            type="ifno"
            @click="ViewSignImgs()"
          >查看</Button></div> -->
          <!-- <div v-if="dataobj.flag == true"><p>电子签名: </p><Button
            
            style="margin-left: 30px;"
            type="ifno"
            @click="ViewSignImgs()"
          >查看</Button></div> -->
        </Card>
      </Col>
      <Col :span="5">
        <Card>
          <p slot="title">积分对象信息</p>
          <p>现金积分人: {{ integralobj.Name }}</p>
          <p>现金积分人电话: {{ integralobj.Tel }}</p>
          <!-- <p>物资积分人: {{ integralobj.MatName }}</p>
          <p>物资积分人电话: {{ integralobj.MatTel }}</p> -->
          <p>现金积分: {{ integralobj.Integral }}</p>
          <!-- <p>物资积分: {{ integralobj.MatIntegral }}</p> -->
        </Card>
        <Card style="margin-top:5px;">
          <p slot="title">审核信息</p>
          <p>过滤器数量: {{ dataobj.PreFilterNum }}</p>
          <p>过滤器类型: {{ dataobj.PreFilterName }}</p>
          <!-- <p>过滤器编码: {{ dataobj.PreFilterCode }}</p> -->
          <div style="word-wrap: break-word;word-break: break-all">过滤器编码: {{dataobj.PreFilterCode}}</div>
          <p>大流量新品数量: {{ dataobj.BigFlowNum }}</p>
          <p>大流量新品备注: {{ dataobj.BigFlowRemark }}</p>
          <p>天力花洒数量: {{ dataobj.ConShowerNum }}</p>
          <p>中央净水数量: {{ dataobj.CentrePWNum }}</p>
          <p>中央净水编码: {{ dataobj.CentrePWCode }}</p>
          <p>止回阀数量: {{ dataobj.CheckValueNum }}</p>
          <p>其他积分: {{ dataobj.OtherIntegral }}</p>
          <p>其他积分备注: {{ dataobj.OtherIntegralRemark }}</p>
          <p>丝口: {{ dataobj.ScrewNum }}</p>
          <p>配套产品: {{ dataobj.OtherProductDesc }}</p>
          <p>使用物料: {{dataobj.UseMaterialName}}</p>
          <p>经销商录单家装公司是否与现场一致: {{dataobj.IsCompanySame=="1" ? "是" : "否"}}</p>
          <p>家装公司名称: {{dataobj.DlCompanyName}}</p>
          <!-- <p>安全阀泄压阀温馨提示: {{dataobj.IsTips=="1" ? "是" : "否"}}</p> -->
        </Card>
      </Col>
      <Col :span="13">
        <Tabs type="card" v-if="agentId == 1||agentId == 5" @on-click="handleClick">
          <TabPane label="全部管路图" name="all">
            <div class="img-list">
              <div v-for="(item, index) in imgurls" @click="viewimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
              </div>
            </div>
          </TabPane>
          <!-- <TabPane label="卫生间" name="wash">
            <div class="img-list">
              <div v-for="(item, index) in waimgurl" @click="viewimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
              </div>
            </div>
          </TabPane>
          <TabPane label="厨房" name="kitchen">
            <div class="img-list">
              <div v-for="(item, index) in kiimgurl" @click="viewimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
              </div>
            </div>
          </TabPane>
          <TabPane label="客厅" name="parlour">
            <div class="img-list">
              <div v-for="(item, index) in paimgurl" @click="viewimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
              </div>
            </div>
          </TabPane>
          <TabPane label="阳台" name="balcony">
            <div class="img-list">
              <div v-for="(item, index) in baimgurl" @click="viewimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
              </div>
            </div>
          </TabPane> -->
          <TabPane label="查询结果" name="field1">
            <div class="img-list">
              <div v-for="(item, index) in fiimgurl" @click="viewimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
              </div>
            </div>
          </TabPane>
          <!-- <TabPane label="其他" name="">
            <div class="img-list">
              <div v-for="(item, index) in imgurl" @click="viewimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
              </div>
            </div>
          </TabPane> -->
          <TabPane label="安装不规范" name="standard">
            <!-- <div style="margin-left: 5px">
              安装不规范备注: {{ StandardDesc }}
            </div> -->
            <div class="img-list">
              <div v-for="(item, index) in stimgurl" @click="viewimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
              </div>
            </div>
          </TabPane>
          <TabPane label="活动图片">
            <div class="img-list">
              <div v-for="(item, index) in actimgurl" @click="viewactimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
                <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
                </span>-->
              </div>
            </div>
          </TabPane>
          <TabPane label="全景图片" name="VRImgs">
            <div class="img-list">
              <div v-for="(item, index) in VRImgsUrl" @click="viewimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
                <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
                </span>-->
              </div>
            </div>
          </TabPane>
          <Button @click="downloadImg" size="small" slot="extra">下载</Button>
        </Tabs>
        <Tabs type="card" v-if="agentId != 1&&agentId != 5" @on-click="handleClick">
          <TabPane label="现场图片">
            <div class="img-list">
              <div v-for="(item, index) in imgurl" @click="viewimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
                <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
              </span> -->
              </div>
            </div>
          </TabPane>
          <TabPane label="活动图片">
            <div class="img-list">
              <div v-for="(item, index) in actimgurl" @click="viewactimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
              </div>
            </div>
          </TabPane>
          <TabPane label="全景图片" name="VRImgs" tab="img">
            <div class="img-list">
              <div v-for="(item, index) in VRImgsUrl" @click="viewimg(item, 1)" :key="index" class="img-cell"
                v-bind:style="{'background-image': 'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat': 'no-repeat','background-color': 'black',}">
                <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
                </span>-->
              </div>
            </div>
          </TabPane>
          <Button @click="downloadImg" size="small" slot="extra">下载</Button>
        </Tabs>
      </Col>
    </Row>

    <Modal v-model="imgviewmodal" type="info" width="880px" draggable="true">
      <p slot="header" style="text-align: center">
        <!-- <Icon type="information-circled"></Icon> -->
        <span>图片预览</span>
      </p>
      <div
        style="text-align: center; display: flex; align-items: center"
        v-if="agentId == 1"
      >
        <div @click="preImgNew()" @keyup.left="preImgNew">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img
          style="max-width: 760px; max-height: 600px;object-fit: contain;"
          :src="currentImgurl"
          alt
        />
        <div @click="nextImgNew()" @keyup.right="nextImgNew">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
      <div
        style="text-align: center; display: flex; align-items: center"
        v-if="agentId != 1"
      >
        <div @click="preImg()">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img
          style="max-width: 760px; max-height: 600px"
          :src="currentImgurl"
          alt=""
        />
        <div @click="nextImg()">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
      <div slot="footer">
          <Button type="info" @click="viewOriginImg">查看原图</Button>
          <Button @click="close">关闭</Button>
      </div>
    </Modal>
    <Modal v-model="actimgviewmodal" type="info" width="880px" draggable="true">
      <p slot="header" style="text-align: center">
        <!-- <Icon type="information-circled"></Icon> -->
        <span>图片预览</span>
      </p>
      <div style="text-align: center; display: flex; align-items: center">
        <div @click="preActImg()">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img
          style="max-width: 760px; max-height: 600px"
          :src="currentActImgurl"
          alt=""
        />
        <div @click="nextActImg()">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
      <div slot="footer">
          <Button type="info" @click="viewActOriginImg">查看原图</Button>
          <Button @click="close">关闭</Button>
      </div>
    </Modal>

    <Modal v-model="VRViewModal" width="450" style="padding:0px" draggable="true">
      <p slot="header" style="text-align:center;">
        <span>VR全景图</span>
      </p>
      <iframe :src="VRViewModalUrl" style="height:500px;width:420px;" frameborder="0"></iframe>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataobj: {},
      integralobj: {},
      imgurl: [],
      imgviewmodal: false,
      currentImgurl: "",
      actimgurl: [],
      actimgviewmodal: false,
      currentActImgurl: "",
      waimgurl: [],
      kiimgurl: [],
      paimgurl: [],
      baimgurl: [],
      stimgurl: [],
      fiimgurl: [],
      VRImgsUrl: [],
      siimgurl: [],
      StandardDesc: "",
      wrimgurl: [],
      agentId: 0,
      active: "all",
      imgurls: [],
      VRViewModal:false,
      VRViewModalUrl:'',
      // base_url: "http://121.41.11.131:8088/"
      base_url: this.$file.url,
      // base_url: "http://localhost:24738/"
    };
  },
  methods: {
    downloadImg: function () {
      var that = this;
      this.$Spin.show({
        render: (h) => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18,
              },
            }),
            h("div", "生成中.."),
          ]);
        },
      });
      this.$http
        .get("/Customer/ExportCustomerImgs", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          that.$Spin.hide();
          window.open(that.$config.fileUrl + res.data);
        });
    },
    viewimg(item) {
      this.imgviewmodal = true;
      this.currentImgurl = item;
    },
    viewactimg(item) {
      this.actimgviewmodal = true;
      this.currentActImgurl = item;
    },
    ViewVR(){
      this.VRViewModal=true;      
      
      this.VRViewModalUrl= "https://"+document.domain+"/#/vrview/"+this.dataobj.WarrantyNum;

    },
    ViewSignImgs(){
      var that=this;
      var id =this.$route.params.id;
      var warranty = that.dataobj.WarrantyType.split(',');
      if(warranty.length == 2){
        // that.$router.push({
        //   name: "customerWarranty_Red",
        //   params: { id: id },
        // });
        var RedUrl = "https://"+document.domain+"/#/customerWarranty_Red/"+id;
        window.open(RedUrl);
      }else{
        // that.$router.push({
        //   name: "customerWarranty_White",
        //   params: { id: id },
        // });
        var WhiteUrl = "https://"+document.domain+"/#/customerWarranty_White/"+id;
        window.open(WhiteUrl);
      }
      // 双重质保
      // if (that.dataobj.WarrantyNum.indexOf("R") == 0) {        
      //   that.$router.push({
      //     name: "customerWarranty_Red",
      //     params: { id: id },
      //   });
      // }
      // // 产品质保
      // if (that.dataobj.WarrantyNum.indexOf("W") == 0) {
      //   that.$router.push({
      //     name: "customerWarranty_White",
      //     params: { id: id },
      //   });
      // }
    },
    close(){
      this.imgviewmodal=false;
      this.actimgviewmodal=false;
    },
    viewOriginImg(){
      window.open(this.currentImgurl);
    },
    viewActOriginImg(){
      window.open(this.currentActImgurl);
    },

    preImg() {
      var index = this.imgurl.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index == 0) {
        nextImgUrl = this.imgurl[this.imgurl.length - 1];
      } else {
        nextImgUrl = this.imgurl[index - 1];
      }
      this.currentImgurl = nextImgUrl;
    },
    nextImg() {
      var index = this.imgurl.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index < this.imgurl.length - 1) {
        nextImgUrl = this.imgurl[index + 1];
      } else {
        nextImgUrl = this.imgurl[0];
      }
      this.currentImgurl = nextImgUrl;
    },
    preImgNew() {
      var pic = this.active;
      switch (pic) {
        case "all":
          var aindex = this.imgurls.indexOf(this.currentImgurl);
          var anextImgUrl = "";
          if (aindex == 0) {
            anextImgUrl = this.imgurls[this.imgurls.length - 1];
          } else {
            anextImgUrl = this.imgurls[aindex - 1];
          }
          this.currentImgurl = anextImgUrl;
          break;
        // case "wash":
        //   var windex = this.waimgurl.indexOf(this.currentImgurl);
        //   var wnextImgUrl = "";
        //   if (windex == 0) {
        //     wnextImgUrl = this.waimgurl[this.waimgurl.length - 1];
        //   } else {
        //     wnextImgUrl = this.waimgurl[windex - 1];
        //   }
        //   this.currentImgurl = wnextImgUrl;
        //   break;
        // case "kitchen":
        //   var kindex = this.kiimgurl.indexOf(this.currentImgurl);
        //   var knextImgUrl = "";
        //   if (kindex == 0) {
        //     knextImgUrl = this.kiimgurl[this.kiimgurl.length - 1];
        //   } else {
        //     knextImgUrl = this.kiimgurl[kindex - 1];
        //   }
        //   this.currentImgurl = knextImgUrl;
        //   break;
        // case "parlour":
        //   var pindex = this.paimgurl.indexOf(this.currentImgurl);
        //   var pnextImgUrl = "";
        //   if (pindex == 0) {
        //     pnextImgUrl = this.paimgurl[this.paimgurl.length - 1];
        //   } else {
        //     pnextImgUrl = this.paimgurl[pindex - 1];
        //   }
        //   this.currentImgurl = pnextImgUrl;
        //   break;
        // case "balcony":
        //   var bindex = this.baimgurl.indexOf(this.currentImgurl);
        //   var bnextImgUrl = "";
        //   if (bindex == 0) {
        //     bnextImgUrl = this.baimgurl[this.baimgurl.length - 1];
        //   } else {
        //     bnextImgUrl = this.baimgurl[bindex - 1];
        //   }
        //   this.currentImgurl = bnextImgUrl;
        //   break;
        case "field1":
          var findex = this.fiimgurl.indexOf(this.currentImgurl);
          var fnextImgUrl = "";
          if (findex == 0) {
            fnextImgUrl = this.fiimgurl[this.fiimgurl.length - 1];
          } else {
            fnextImgUrl = this.fiimgurl[findex - 1];
          }
          this.currentImgurl = fnextImgUrl;
          break;
        case "standard":
          var sindex = this.stimgurl.indexOf(this.currentImgurl);
          var snextImgUrl = "";
          if (sindex == 0) {
            snextImgUrl = this.stimgurl[this.stimgurl.length - 1];
          } else {
            snextImgUrl = this.stimgurl[sindex - 1];
          }
          this.currentImgurl = snextImgUrl;
          break;
        case "VRImgs":
          var vindex = this.VRImgsUrl.indexOf(this.currentImgurl);
          var vnextImgUrl = "";
          if (vindex == 0) {
            vnextImgUrl = this.VRImgsUrl[this.VRImgsUrl.length - 1];
          } else {
            vnextImgUrl = this.VRImgsUrl[vindex - 1];
          }
          this.currentImgurl = vnextImgUrl;
          break;
        default:
          var index = this.imgurl.indexOf(this.currentImgurl);
          var nextImgUrl = "";
          if (index == 0) {
            nextImgUrl = this.imgurl[this.imgurl.length - 1];
          } else {
            nextImgUrl = this.imgurl[index - 1];
          }
          this.currentImgurl = nextImgUrl;
          break;
      }
    },
    nextImgNew() {
      var pic = this.active;
      switch (pic) {
        case "all":
          var aindex = this.imgurls.indexOf(this.currentImgurl);
          var anextImgUrl = "";
          if (aindex < this.imgurls.length - 1) {
            anextImgUrl = this.imgurls[aindex + 1];
          } else {
            // anextImgUrl = this.imgurls[aindex];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            anextImgUrl = this.imgurls[0];
          }
          this.currentImgurl = anextImgUrl;
          break;
        // case "wash":
        //   var windex = this.waimgurl.indexOf(this.currentImgurl);
        //   var wnextImgUrl = "";
        //   if (windex < this.waimgurl.length - 1) {
        //     wnextImgUrl = this.waimgurl[windex + 1];
        //   } else {
        //     wnextImgUrl = this.waimgurl[0];
        //   }
        //   this.currentImgurl = wnextImgUrl;
        //   break;
        // case "kitchen":
        //   var kindex = this.kiimgurl.indexOf(this.currentImgurl);
        //   var knextImgUrl = "";
        //   if (kindex < this.kiimgurl.length - 1) {
        //     knextImgUrl = this.kiimgurl[kindex + 1];
        //   } else {
        //     knextImgUrl = this.kiimgurl[0];
        //   }
        //   this.currentImgurl = knextImgUrl;
        //   break;
        // case "parlour":
        //   var pindex = this.paimgurl.indexOf(this.currentImgurl);
        //   var pnextImgUrl = "";
        //   if (pindex < this.paimgurl.length - 1) {
        //     pnextImgUrl = this.paimgurl[pindex + 1];
        //   } else {
        //     pnextImgUrl = this.paimgurl[0];
        //   }
        //   this.currentImgurl = pnextImgUrl;
        //   break;
        // case "balcony":
        //   var bindex = this.baimgurl.indexOf(this.currentImgurl);
        //   var bnextImgUrl = "";
        //   if (bindex < this.baimgurl.length - 1) {
        //     bnextImgUrl = this.baimgurl[bindex + 1];
        //   } else {
        //     bnextImgUrl = this.baimgurl[0];
        //   }
        //   this.currentImgurl = bnextImgUrl;
        //   break;
        case "field1":
          var findex = this.fiimgurl.indexOf(this.currentImgurl);
          var fnextImgUrl = "";
          if (findex < this.fiimgurl.length - 1) {
            fnextImgUrl = this.fiimgurl[findex + 1];
          } else {
            // bnextImgUrl = this.baimgurl[bindex];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            fnextImgUrl = this.fiimgurl[0];
          }
          this.currentImgurl = fnextImgUrl;
          break;
        case "standard":
          var sindex = this.stimgurl.indexOf(this.currentImgurl);
          var snextImgUrl = "";
          if (sindex < this.stimgurl.length - 1) {
            snextImgUrl = this.stimgurl[sindex + 1];
          } else {
            // bnextImgUrl = this.baimgurl[bindex];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            snextImgUrl = this.stimgurl[0];
          }
          this.currentImgurl = snextImgUrl;
          break;
        case "VRImgs":
          var vindex = this.VRImgsUrl.indexOf(this.currentImgurl);
          var vnextImgUrl = "";
          if (vindex < this.VRImgsUrl.length - 1) {
            vnextImgUrl = this.VRImgsUrl[vindex + 1];
          } else {
            // bnextImgUrl = this.baimgurl[bindex];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            vnextImgUrl = this.VRImgsUrl[0];
          }
          this.currentImgurl = vnextImgUrl;
          break;
        default:
          var index = this.imgurl.indexOf(this.currentImgurl);
          var nextImgUrl = "";
          if (index < this.imgurl.length - 1) {
            nextImgUrl = this.imgurl[index + 1];
          } else {
            // nextImgUrl = this.imgurl[index];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            nextImgUrl = this.imgurl[0];
          }
          this.currentImgurl = nextImgUrl;
          break;
      }
    },
    preActImg() {
      var index = this.actimgurl.indexOf(this.currentActImgurl);
      var nextImgUrl = "";
      if (index == 0) {
        nextImgUrl = this.actimgurl[this.actimgurl.length - 1];
      } else {
        nextImgUrl = this.actimgurl[index - 1];
      }
      this.currentActImgurl = nextImgUrl;
    },
    nextActImg() {
      var index = this.actimgurl.indexOf(this.currentActImgurl);
      var nextImgUrl = "";
      if (index < this.actimgurl.length - 1) {
        nextImgUrl = this.actimgurl[index + 1];
      } else {
        nextImgUrl = this.actimgurl[0];
      }
      this.currentActImgurl = nextImgUrl;
    },
    GetData() {
      this.$http
        .get("/Customer/GetCustomer", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          var that = this;
          this.dataobj = res.data.Data;
          if (res.data.Data.Imgs != null && res.data.Data.Imgs.length > 0) {
            var imgStr = res.data.Data.Imgs;
            var imgarray = imgStr.split(",");
            // var imgUrlArray = [];
            var otherProductArray = res.data.Data.OtherProduct.split(",");
            if (otherProductArray.length >= 3) {
              res.data.Data.OtherProduct = 40;
            } else {
              res.data.Data.OtherProduct = 0;
            }
            var imgUrlArray = imgarray.map((item) => {
              if (item.search("Images") != -1) {
                //老数据的图片路径
                return that.base_url + item;
              } else if (item.search("Images") == -1) {
                //新路径格式 2018年9月10日
                return (
                  that.base_url +
                  "Images/upload/" +
                  res.data.Data.WarrantyNum +
                  "/" +
                  item +
                  ".jpg"
                );
              }
            });
            that.imgurl = imgUrlArray;
          } else {
            that.imgurl = [];
          }
          if (
            res.data.Data.ActImgs != null &&
            res.data.Data.ActImgs.length > 0
          ) {
            var actimgStr = res.data.Data.ActImgs;
            var actimgarray = actimgStr.split(",");
            // var actImgUrlArray = [];

            var actImgUrlArray = actimgarray.map((item) => {
              //路径格式 2020年9月10日
              return (
                that.base_url +
                "Images/activity/" +
                res.data.Data.WarrantyNum +
                "/" +
                item +
                ".jpg"
              );
            });
            that.actimgurl = actImgUrlArray;
          } else {
            that.actimgurl = [];
          }
        });
    },
    GetDataNew(url) {
      this.$http
        .get("/Customer/GetCustomerPic", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          var that = this;
          this.dataobj = res.data.Data.customer;
          // 查询管路图是否合格，未设置则按钮可用，已设置则按钮不可用
          that.ischecks = res.data.Data.customer.IsPipeCheck;
          if (that.ischecks == 0) {
            that.dis = false;
          } else {
            that.dis = true;
          }
          // 判断是否为电子质保卡
          var warrantytype = res.data.Data.customer.WarrantyType.split(',');
          var iswarranty = res.data.Data.customer.IsWebWarrantyNum;
          if(iswarranty == 1 && warrantytype.length == 2){
            this.dataobj.flag = true;
          }else if(iswarranty == 1 && warrantytype.length == 1){
            this.dataobj.flag = true;
          }else{
            this.dataobj.flag = false;
          }

          switch (url) {
            case "all":
              that.imgurls = [];
              if (res.data.Data.customerex != null && res.data.Data.customerex.WashImgs != null && res.data.Data.customerex.WashImgs.length > 0) {
                var awimgStr = res.data.Data.customerex.WashImgs;
                var awimgarray = awimgStr.split(",");
                // var awimgUrlArray = [];
                var awimgUrlArray = awimgarray.map((item) => {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.Data.customer.WarrantyNum +
                    "/" +
                    "wash" +
                    "/" +
                    item +
                    ".jpg"
                  );
                });
                that.waimgurl = awimgUrlArray;
                that.imgurls = that.imgurls.concat(that.waimgurl);
              }
              if (res.data.Data.customerex != null && res.data.Data.customerex.KitchenImgs != null && res.data.Data.customerex.KitchenImgs.length > 0) {
                var akimgStr = res.data.Data.customerex.KitchenImgs;
                var akimgarray = akimgStr.split(",");
                // var akimgUrlArray = [];
                var akimgUrlArray = akimgarray.map((item) => {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.Data.customer.WarrantyNum +
                    "/" +
                    "kitchen" +
                    "/" +
                    item +
                    ".jpg"
                  );
                });
                that.kiimgurl = akimgUrlArray;
                that.imgurls = that.imgurls.concat(that.kiimgurl);
              }
              if (res.data.Data.customerex != null && res.data.Data.customerex.ParlourImgs != null && res.data.Data.customerex.ParlourImgs.length > 0) {
                var apimgStr = res.data.Data.customerex.ParlourImgs;
                var apimgarray = apimgStr.split(",");
                // var apimgUrlArray = [];
                var apimgUrlArray = apimgarray.map((item) => {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.Data.customer.WarrantyNum +
                    "/" +
                    "parlour" +
                    "/" +
                    item +
                    ".jpg"
                  );
                });
                that.paimgurl = apimgUrlArray;
                that.imgurls = that.imgurls.concat(that.paimgurl);
              }
              if (res.data.Data.customerex != null && res.data.Data.customerex.BalconyImgs != null && res.data.Data.customerex.BalconyImgs.length > 0) {
                var abimgStr = res.data.Data.customerex.BalconyImgs;
                var abimgarray = abimgStr.split(",");
                // var abimgUrlArray = [];
                var abimgUrlArray = abimgarray.map((item) => {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.Data.customer.WarrantyNum +
                    "/" +
                    "balcony" +
                    "/" +
                    item +
                    ".jpg"
                  );
                });
                that.baimgurl = abimgUrlArray;
                that.imgurls = that.imgurls.concat(that.baimgurl);
              }
              if (res.data.Data.customerex != null && res.data.Data.customerex.Field1 != null && res.data.Data.customerex.Field1.length > 0) {
                var afimgStr = res.data.Data.customerex.Field1;
                var afimgarray = afimgStr.split(",");
                // var abimgUrlArray = [];
                var afimgUrlArray = afimgarray.map((item) => {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.Data.customer.WarrantyNum +
                    "/" +
                    "field1" +
                    "/" +
                    item +
                    ".jpg"
                  );
                });
                that.fiimgurl = afimgUrlArray;
                that.imgurls = that.imgurls.concat(that.fiimgurl);
              }
              if (res.data.Data.customerex != null && res.data.Data.customerex.StandardImgs != null && res.data.Data.customerex.StandardImgs.length > 0) {
                var asimgStr = res.data.Data.customerex.StandardImgs;
                var asimgarray = asimgStr.split(",");
                // var asimgUrlArray = [];
                var asimgUrlArray = asimgarray.map((item) => {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.Data.customer.WarrantyNum +
                    "/" +
                    "standard" +
                    "/" +
                    item +
                    ".jpg"
                  );
                });
                that.stimgurl = asimgUrlArray;
                that.imgurls = that.imgurls.concat(that.stimgurl);
              }
              if (res.data.Data.customer.Imgs != null && res.data.Data.customer.Imgs.length > 0) {
                var aimgStr = res.data.Data.customer.Imgs;
                var aimgarray = aimgStr.split(",");
                // var aimgUrlArray = [];
                var aimgUrlArray = aimgarray.map((item) => {
                  if (item.search("Images") != -1) {
                    //老数据的图片路径
                    return that.base_url + item;
                  } else if (item.search("Images") == -1) {
                    //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      item +
                      ".jpg"
                    );
                  }
                });
                that.imgurl = aimgUrlArray;
                that.imgurls = that.imgurls.concat(that.imgurl);
              }
              if (res.data.Data.customerex != null && res.data.Data.customerex.WarrantyImgs != null && res.data.Data.customerex.WarrantyImgs.length > 0) {
                var awrimgStr = res.data.Data.customerex.WarrantyImgs;
                var awrimgarray = awrimgStr.split(",");
                // var asimgUrlArray = [];
                var awrimgUrlArray = awrimgarray.map((item) => {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.Data.customer.WarrantyNum +
                    "/" +
                    "warranty" +
                    "/" +
                    item +
                    ".jpg"
                  );
                });
                that.wrimgurl = awrimgUrlArray;
                that.imgurls = that.imgurls.concat(that.wrimgurl);
              }
              // if (res.data.Data.customerex!=null&&res.data.Data.customerex.SignImgs != null && res.data.Data.customerex.SignImgs.length > 0) {
              //   var signImgStr = res.data.Data.customerex.SignImgs;
              //   var signImgarray = signImgStr.split(",");
              //   var signimgUrlArray = [];
              //   var signimgUrlArray = signImgarray.map(item => {
              //     //新路径格式 2018年9月10日
              //       return (
              //         that.base_url +
              //         "Images/upload/" +
              //         res.data.Data.customer.WarrantyNum +
              //         "/" +
              //         "sign" +
              //         "/" +
              //         item +
              //         ".jpg"
              //       );
              //   });
              //   that.signimgurl = signimgUrlArray;
              //   that.imgurls = that.imgurls.concat(that.signimgurl);
              // }
              break;

            case "field1":
              if (res.data.Data.customerex != null && res.data.Data.customerex.Field1 != null && res.data.Data.customerex.Field1.length > 0) {
                var fimgStr = res.data.Data.customerex.Field1;
                var fimgarray = fimgStr.split(",");
                // var bimgUrlArray = [];
                var fimgUrlArray = fimgarray.map((item) => {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.Data.customer.WarrantyNum +
                    "/" +
                    url +
                    "/" +
                    item +
                    ".jpg"
                  );
                });
                that.fiimgurl = fimgUrlArray;
              } else {
                that.fiimgurl = [];
              }
              break;
            case "standard":
              if (res.data.Data.customerex != null && res.data.Data.customerex.StandardImgs != null && res.data.Data.customerex.StandardImgs.length > 0) {
                var simgStr = res.data.Data.customerex.StandardImgs;
                var simgarray = simgStr.split(",");
                // var simgUrlArray = [];
                var simgUrlArray = simgarray.map((item) => {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.Data.customer.WarrantyNum +
                    "/" +
                    url +
                    "/" +
                    item +
                    ".jpg"
                  );
                });
                that.stimgurl = simgUrlArray;
              } else {
                that.stimgurl = [];
              }
              // if (
              //   res.data.Data.customerex != null &&
              //   res.data.Data.customerex.StandardDesc != null
              // ) {
              //   that.StandardDesc = res.data.Data.customerex.StandardDesc;
              // }
              break;
            case "VRImgs":
              if (res.data.Data.customerex != null && res.data.Data.customerex.VRImgs != null && res.data.Data.customerex.VRImgs.length > 0) {
                var vimgStr = res.data.Data.customerex.VRImgs;
                var vimgarray = vimgStr.split(",");
                // var vimgUrlArray = [];
                var vimgUrlArray = vimgarray.map((item) => {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.Data.customer.WarrantyNum +
                    "/" +
                    url +
                    "/" +
                    item +
                    ".jpg"
                  );
                });
                that.VRImgsUrl = vimgUrlArray;
              } else {
                that.VRImgsUrl = [];
              }
              // if (
              //   res.data.Data.customerex != null &&
              //   res.data.Data.customerex.StandardDesc != null
              // ) {
              //   that.StandardDesc = res.data.Data.customerex.StandardDesc;
              // }
              break;
            default:
              if (res.data.Data.customer.Imgs != null && res.data.Data.customer.Imgs.length > 0) {
                var imgStr = res.data.Data.customer.Imgs;
                var imgarray = imgStr.split(",");
                // var imgUrlArray = [];
                var otherProductArray =
                  res.data.Data.customer.OtherProduct.split(",");
                if (otherProductArray.length >= 3) {
                  res.data.Data.customer.OtherProduct = 40;
                } else {
                  res.data.Data.customer.OtherProduct = 0;
                }
                var imgUrlArray = imgarray.map((item) => {
                  if (item.search("Images") != -1) {
                    //老数据的图片路径
                    return that.base_url + item;
                  } else if (item.search("Images") == -1) {
                    //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      item +
                      ".jpg"
                    );
                  }
                });
                that.imgurl = imgUrlArray;
              } else {
                that.imgurl = [];
              }
              break;
          }
          // 活动图片
          if (res.data.Data.customer.ActImgs != null && res.data.Data.customer.ActImgs.length > 0) {
            var actimgStr = res.data.Data.customer.ActImgs;
            var actimgarray = actimgStr.split(",");
            // var actImgUrlArray = [];

            var actImgUrlArray = actimgarray.map((item) => {
              //路径格式 2020年9月10日
              return (
                that.base_url +
                "Images/activity/" +
                res.data.Data.customer.WarrantyNum +
                "/" +
                item +
                ".jpg"
              );
            });
            that.actimgurl = actImgUrlArray;
          } else {
            that.actimgurl = [];
          }
          // 签名图片
          if (res.data.Data.customerex!=null&&res.data.Data.customerex.SignImgs != null && res.data.Data.customerex.SignImgs.length > 0) {
              var siimgStr = res.data.Data.customerex.SignImgs;
              var siimgarray = siimgStr.split(",");
              // var pimgUrlArray = [];
              var siimgUrlArray = siimgarray.map(item => {
                //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.Data.customer.WarrantyNum +
                    "/sign/" +
                    item +
                    ".jpg"
                  );
              });
              that.siimgurl = siimgUrlArray;
            } else {
              that.siimgurl = [];
            }
        });
    },
    GetIntegral() {
      this.$http
        .get("/Customer/GetIntegral", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          // var that = this;
          this.integralobj = res.data.Data;
        });
    },
    handleClick: function (e) {
      this.active = e;
      this.GetDataNew(e);
    },
    GoBack: function () {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    Extract: function () {
      // var that = this;
      var url = "/Customer/DoExtractAudit";
      this.$http
        .get(url, { params: { customerId: this.$route.params.id } })
        .then(() => {
          this.$Message.info("操作成功");
        });
    },
    pagination(pageSize, currentPage, arr) {
      var skipNum = (currentPage - 1) * pageSize;
      var newArr = (skipNum + pageSize >= arr.length) ? arr.slice(skipNum, arr.length) : arr.slice(skipNum, skipNum + pageSize);
      return newArr;
    }
  },
  created() {
    // this.GetData();
    this.agentId = this.$route.params.agentId;
    if (this.agentId == 1 ||this.agentId == 5|| this.agentId == 56 || this.agentId == 22 || this.agentId == 12) {
      this.GetDataNew(this.active);
    } else {
      this.GetData();
    }
    this.GetIntegral();
  },
};
</script>

<style>
.detail-form {
}
</style>
