<template>
  <div style="height: 100%" v-if="showView">
    <audio
      v-if="showMusic"
      :src="mus"
      class="media-audio"
      loop
      autoplay
      ref="MusicPlay"
    ></audio>
    <!-- <img
      v-if="customerInfo.AgentId == 1 && customerInfo.PipelineName == '红宝石抗菌净水管' "
      class="logoimg"
      :src="logourl"
      alt=""
    /> -->
    <!--vr通知栏-->
    <van-notice-bar
      left-icon="volume-o"
      color="#FFFFFF"
      background="#3D3D3D"
      class="logoimg"
      :text="noticeText"
    >
    </van-notice-bar>
    <!--logo-->
    <img class="logo" :src="logourl" alt="" />
    <transition name="fade">
      <div class="Info" v-show="showInfo">
        <!-- <van-row v-if="customerInfo.ProjectName!=''">          
          <van-col  span="20"></van-col>
          <van-col  class="detail-text" style="  text-align: right;"  span="4">关闭</van-col>
        </van-row> -->
        <van-row v-if="customerInfo.ProjectName != ''">
          <van-col span="8">项目名称:</van-col>
          <van-col class="detail-text" span="16">{{
            customerInfo.ProjectName
          }}</van-col>
        </van-row>
        <van-row v-if="customerInfo.Doormodel != ''">
          <van-col span="8">户型:</van-col>
          <van-col class="detail-text" span="16">{{
            customerInfo.Doormodel
          }}</van-col>
        </van-row>
        <van-row v-if="customerInfo.SDWorkerName != ''">
          <van-col span="8">水电工程师:</van-col>
          <van-col class="detail-text" span="16"
            >{{ customerInfo.SDWorkerName }}
            {{ customerInfo.SDWorkerTel }}</van-col
          >
        </van-row>
        <van-row v-if="customerInfo.ProjectCompany != ''">
          <van-col span="8">施工单位:</van-col>
          <van-col class="detail-text" span="16">{{
            customerInfo.ProjectCompany
          }}</van-col>
        </van-row>
        <van-row v-if="customerInfo.ConsultingTel != ''">
          <van-col span="8">咨询热线:</van-col>
          <van-col class="detail-text" span="16">{{
            customerInfo.ConsultingTel
          }}</van-col>
        </van-row>
        <van-row v-if="customerInfo.CustAddress != ''">
          <van-col span="8">项目地址:</van-col>
          <van-col class="detail-text" span="16">{{
            customerInfo.CustAddress
          }}</van-col>
        </van-row>
      </div>
    </transition>
    <!-- 自定义导航栏 -->
    <div class="navbar">
      <div v-if="showMusic" class="button">
        <van-icon name="volume" size="20" @click="musicClick" />
      </div>
      <div class="button">
        <van-icon name="question" size="20" @click="msgClick" />
      </div>
      <div class="button">
        <van-icon name="eye" size="20" @click="hideRoom" />
      </div>
    </div>
    <div id="viewer" style="width: 100vw; height: 100vh"></div>

    <transition name="fade">
      <div v-show="showRoom" class="imgList" id="imgList">
        <div class="warp">
          <div
            class="imgList-det"
            v-for="(item, index) in imgList"
            @click="changeImg(index)"
            :key="index"
          >
            <p class="title">{{ item.name }}</p>
            <img class="img" :src="item.url" alt="" />
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div v-else>
    <img src="../../assets/logo.jpg" fit="contain" width="100%" alt="" />
    <div class="btn">
      <van-button
        round
        type="default"
        size="small"
        style="width: 65px"
        @click="tovrview"
      >
        跳过{{ count }}
      </van-button>
    </div>
  </div>
</template>
        

<script>
import VConsole from "vconsole";
import { Viewer } from "photo-sphere-viewer";
import wx from "weixin-js-sdk";
import { Animation } from "photo-sphere-viewer";
import { MarkersPlugin } from "photo-sphere-viewer/dist/plugins/markers";
import "photo-sphere-viewer/dist/plugins/markers.css";
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import { Col, Row, NoticeBar, Icon } from "vant";

var viewer = {};
var markersPlugin = {};
function getUrlParam(key) {
  var str = window.location.search || window.location.hash || "";
  var str2 = str.substr(1);
  var arr = str2.split("&");
  var obj = {};
  for (var i = 0; i < arr.length; i++) {
    var param = arr[i].split("=");
    obj[param[0]] = decodeURIComponent(param[1]);
  }

  return obj[key];
}
export default {
  data() {
    return {
      vrLogoObject: {
        id: "image",
        longitude: "0deg",
        width: 150,
        height: 150,
        latitude: "-90deg",
        image: require("../../assets/vr_logo.png"),
      },
      noticeText: "坚守敬畏之心，铸造匠心精品，家装好水管，就用天力管！",
      logourl: require("../../assets/title_logo.png"),
      imgList: [],
      btncontent: "🎵",
      isFirstPlay: true,
      musicTF: true, //是否正在播放
      mus: "",
      showMusic: true,
      customerInfo: {},
      showInfo: false,
      showView: false,
      showRoom: true,
      count: 0,
      toAddMarker: [],
      currentImg: {},
      lock: false,
    };
  },
  created: function () {},
  mounted: function () {
    this.clickJump();
    var debugModel = getUrlParam("debug");
    console.log("debugModel:" + debugModel); //开启调试模式
    if (debugModel == "true") {
      var vConsole = new VConsole({ theme: "dark" });
    }

    // this.musicPlay();
  },
  methods: {
    wxconfig: function () {
      var that = this;
      var url = encodeURIComponent(location.href.split("#")[0]);
      // url=encodeURIComponent("https://sale.reaca.net")
      this.$http.get("WeChatApi/GetJSConfig?url=" + url).then((response) => {
        console.log(response);
        wx.config({
          debug: false,
          appId: response.data.Data.appId,
          timestamp: parseInt(response.data.Data.timestamp),
          nonceStr: response.data.Data.noncestr,
          signature: response.data.Data.sign,
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
        });

        wx.ready(function () {
          var title = that.customerInfo.CustAddress;
          var logoUrl = "https://oss.reaca.net/webPic/vr_logo.png";
          if (that.customerInfo.CompanyName != ""&&that.customerInfo.IsTitle == 1) {
            title = that.customerInfo.CompanyName + "-" + title;
          } else if(that.customerInfo.Company2Name != ""&&that.customerInfo.Company2Name != null){
            title = that.customerInfo.Company2Name + "-" + title;
          }
          if (that.customerInfo.CompanyLogoUrl != "") {
            logoUrl = that.$file.url+"Images/Company/"+that.customerInfo.CompanyID +"/LogoImg/"+that.customerInfo.CompanyLogoUrl+".jpg";
          }
          // wx.updateAppMessageShareData({
          //   title: "天力管健康源-VR全景", // 分享标题
          //   desc: that.customerInfo.CustAddress, // 分享描述
          //   link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          //   imgUrl: "https://oss.reaca.net/webPic/vr_logo.png", // require("../../assets/vr_logo.png"), // 分享图标
          //   success: function () {
          //     console.log("success", that.customerInfo.CustAddress);
          //     // 设置成功
          //   },
          // });
          wx.updateAppMessageShareData({
            title: title, // 分享标题
            desc: "天力管健康源", // 分享描述
            link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: logoUrl, // require("../../assets/vr_logo.png"), // 分享图标
            success: function () {
              console.log("success", that.customerInfo.CustAddress);
              // 设置成功
            },
          });

          // wx.updateTimelineShareData({
          //   title: "天力管健康源-VR全景", // 分享标题
          //   link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          //   imgUrl: "https://oss.reaca.net/webPic/vr_logo.png", //require("../../assets/vr_logo.png"), // 分享图标
          //   success: function () {
          //     console.log("success");
          //     // 设置成功
          //   },
          // });
          wx.updateTimelineShareData({
            title: title, // 分享标题
            link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: logoUrl, //require("../../assets/vr_logo.png"), // 分享图标
            success: function () {
              console.log("success");
              // 设置成功
            },
          });
        });
        // that.LoadImg(res);
      });
    },
    changeImg: function (idx) {
      this.currentImg = this.imgList[idx];
      var url = this.imgList[idx].url;
      var that = this;

      viewer.setPanorama(url).then(() => {
        viewer.animate({
          longitude: that.currentImg.lgt,
          latitude: that.currentImg.lat,
          zoom: 1,
          speed: "20rpm",
        });
        that.LoadMaker(function callback(params) {
          console.log("切换完成", url);
          that.lock = false;
          that.RefreshMakers();
        });
      });
    },
    LoadData: function () {
      var warranty = this.$route.params.warranty;
      var that = this;
      this.$http
        .get("VRImgInfo/GetVRImgInfo?warrantyNum=" + warranty)
        .then((res) => {
          console.log(res);

          //不是长沙地区不播放音乐
          if (res.data.Data.AgentID == 2) {
            that.showMusic = false;
          }

          if (
            res.data.Data.PipelineName == "不锈钢管(16)" ||
            res.data.Data.PipelineName == "不锈钢管(20)" ||
            res.data.Data.PipelineName == "不锈钢管(25)" ||
            res.data.Data.PipelineName == "不锈钢管(32)"
          ) {
            that.mus = require("../../assets/voice/不锈钢vr.mp3");
          } else {
            that.mus = require("../../assets/voice/vr.mp3");
          }

          if (res.data.Data.VRLogoUrl != undefined) {
            if (res.data.Data.VRLogoUrl.length > 0) {
              that.vrLogoObject.image = res.data.Data.VRLogoUrl;
              that.mus = require("../../assets/voice/vr2.mp3");
            }
          }

          if (res.data.Data.VRNoticeText != undefined) {
            if (res.data.Data.VRNoticeText.length > 0) {
              that.noticeText = res.data.Data.VRNoticeText;
            }
          }
          if (res.data.Data.CompanyLogoUrl != undefined) {
            if (res.data.Data.CompanyLogoUrl.length > 0) {
              var companyLogoUrl= `${that.$file.url}Images/Company/${res.data.Data.CompanyID}/LogoImg/${res.data.Data.CompanyLogoUrl}.jpg`;
              that.vrLogoObject.image = companyLogoUrl;
              that.vrLogoObject.width=220;
              that.vrLogoObject.height=220;
              that.mus = require("../../assets/voice/vr2.mp3");
            }
          }

          document.title = "天力管健康源-" + res.data.Data.CustAddress;
          var imgFileArray = res.data.Data.VRImgs.split(",");
          var descArray = [];
          that.customerInfo = res.data.Data;
          that.wxconfig();
          if (res.data.Data.VRImgsDesc.length > 0) {
            descArray = res.data.Data.VRImgsDesc.split(",");
          } else {
            imgFileArray.map(() => {
              descArray.push("未知");
            });
          }
          imgFileArray.map((item, idx) => {
            var name = "未知";
            try {
              name = descArray[idx];
            } catch (error) {
              console.error("场景没有命名");
            }
            var lgt = 0;
            var lat = 0;
            if (res.data.Data.VRExInfo.length > 0) {
              res.data.Data.VRExInfo.map((ex) => {
                if (ex.ImgFileName == item) {
                  lgt = ex.InitLgt;
                  lat = ex.InitLat;
                }
              });
            }

            that.imgList.push({
              // title: descArray[idx],
              name: name,
              filename: item,
              lgt: lgt,
              lat: lat,
              url:
                that.$file.url +
                "Images/upload/" +
                warranty +
                "/VRImgs/" +
                item +
                ".jpg",
            });
          });

          that.currentImg = that.imgList[0];
          that.LoadMaker(undefined, true);
        });
    },
    LoadMaker: function (cb, needInit) {
      var that = this;
      this.$http
        .get("VRImgInfo/GetImgMakers?filename=" + that.currentImg.filename)
        .then((res) => {
          if (res.data.Data.length > 0) {
            var makers = JSON.parse(res.data.Data);
            that.toAddMarker = makers;
          } else {
            that.toAddMarker = [];
            console.log(that.toAddMarker);
          }
          if (needInit) {
            that.InitVR();
          }
          try {
            if (cb != undefined) {
              cb();
            }
          } catch (error) {
            console.log("cb null");
          }
          // cb();
        });
    },
    RefreshMakers: function () {
      var that = this;
      markersPlugin = viewer.getPlugin(MarkersPlugin);
      if (!this.lock) {
        markersPlugin.clearMarkers();
        this.toAddMarker.map((item) => {
          markersPlugin.addMarker({
            id: item.markerid,
            longitude: item.longitude,
            latitude: item.latitude,
            html: `<img style=" width: 50px;height: 50px;" src="http://reaca-font2.oss-cn-hangzhou.aliyuncs.com/pic/ahead.gif"/> <b style="text-align: center;
                  color: #FFFFFF;
                  font-family: MicrosoftYahei;
                  font-size: 12px;
                  padding: 3px 10px;
                  background-color: rgba(7, 43, 248, 0.8);
                  margin: 0px 0px 0px 0px;
                  border-radius: 5px;">${item.markerName}</b>`,
            style: {
              width: "70px",
              color: "#102d4a",
              fontSize: "15px",
              fontFamily: "Helvetica, sans-serif",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            },
            data: item.data,
          });
          // markersPlugin.renderMarkers();
        });
        markersPlugin.addMarker(that.vrLogoObject);
        console.log(markersPlugin);
        this.lock = true;
      }
    },
    InitVR: function (idx) {
      var that = this;
      var img = that.currentImg;
      console.log(MarkersPlugin);
      // console.log(new Viewer());
      viewer = new Viewer({
        container: document.querySelector("#viewer"),
        // withCredentials:true,
        panorama: img.url,
        size: {
          width: "100%",
          height: "95vh",
        },
        maxFov: 100,
        minFov: 10,
        defaultZoomLvl: 1,
        // mousewheel: false,
        touchmoveTwoFingers: false,
        moveSpeed: 1,
        loadingImg: null,
        loadingTxt: "加载中 请稍后...",
        navbar: false,
        plugins: [
          [
            MarkersPlugin,
            {
              markers: [that.vrLogoObject],
            },
          ],
        ],
      });
      viewer.on("ready", () => {
        new Animation({
          properties: {
            lat: { start: -Math.PI / 2, end: img.lat },
            long: { start: Math.PI, end: img.lgt },
            zoom: { start: 0, end: 0 },
            fisheye: { start: 4, end: 0 },
          },
          duration: 2000,
          easing: "inOutQuad",
          onTick: (properties) => {
            viewer.setOption("fisheye", properties.fisheye);
            viewer.rotate({
              longitude: properties.long,
              latitude: properties.lat,
            });
            viewer.zoom(properties.zoom);
            that.RefreshMakers();
          },
        });
      });
      viewer.plugins.markers.on("select-marker", function (e, marker, data) {
        var toidx = 0;
        that.imgList.map((item, idx) => {
          if (item.filename == marker.data.to.filename) {
            toidx = idx;
          }
        });
        that.changeImg(toidx);
      });
      viewer.on("click", () => {
        console.log("click");
        if (that.isFirstPlay) {
          that.musicPlay();
        }
      });

      viewer.on("position-updated", () => {
        if (that.isFirstPlay) {
          that.musicPlay();
        }
      });
    },
    /**
     * 暂停音乐并停止旋转
     */
    musicPause() {
      if (this.showMusic) {
        this.$refs.MusicPlay.pause();
        this.musicTF = false;
        this.isFirstPlay = false;
      }
    },

    /**
     * 播放音乐并开始旋转
     */
    musicPlay() {
      if (this.showMusic) {
        if (this.musicPlay) {
          this.$refs.MusicPlay.play();
          this.musicTF = true;
        }
      }
    },
    musicClick() {
      if (this.musicTF) {
        this.musicPause();
        this.$Message.info("音乐已关闭");
      } else {
        this.musicPlay();
        this.$Message.info("音乐已开启");
      }
    },
    msgClick() {
      this.showInfo = !this.showInfo;
    },

    hideRoom() {
      this.showRoom = !this.showRoom;
    },
    //3秒后进入跳转页面
    clickJump() {
      const timejump = 3;
      if (!this.timer) {
        this.count = timejump;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= timejump) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            this.showView = true;
            this.LoadData();
          }
        }, 1000);
      }
    },
    tovrview() {
      this.showView = true;
    },
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [NoticeBar.name]: NoticeBar,
    [Icon.name]: Icon,
  },
};
</script>

<style scoped>
.detail-text {
  letter-spacing: 1px;
}
.Info {
  /* height: 300 px; */
  position: absolute;
  top: 80px;
  width: 60%;
  padding: 0px 0px 0px 10px;
  background-color: rgba(61, 61, 61, 0.6);
  z-index: 999;
  text-align: left;
  color: white;
  border-radius: 3px;
  font-size: 14px;
  margin-left: 80px;
  letter-spacing: 2px;
}
.logo {
  position: absolute;
  top: 75px;
  width: 25%;
  z-index: 999;
  text-align: left;
  color: white;
  border-radius: 3px;
  letter-spacing: 2px;
  margin-left: 10px;
}
.van-row {
  margin-top: 5px;
}
.logoimg {
  height: 5vh;
}
.imgList {
  bottom: 0px;
  height: 100px;
  width: 100%;
  z-index: 99;
  position: absolute;
}
.warp {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
}
.imgList-det {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.title {
  color: aliceblue;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: small;
}
.img {
  width: 70px;
  height: 70px;
  border-radius: 8px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: block;
  position: fixed;
  z-index: 90;
  width: 35px;
  border-radius: 5px;
  background: rgba(61, 61, 61, 0.5);
  -webkit-transition: bottom ease-in-out 0.1s;
  transition: bottom ease-in-out 0.1s;
}

.navbar {
  top: 80px;
  right: 10px;
}

.button {
  width: 30px;
  height: 30px;
  padding: 5.5px;
  margin: 5px 2.5px 5px 2.5px;
}

.button {
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
}
</style>
